import { reminderBanner } from '@/constants/packagesAndOrderForm.ts';

export default {
  setPackagesAndOrderFormPopupStatus({ commit }, payload) {
    commit('SET_PACKAGES_AND_ORDER_FORM_POPUP_STATUS', payload);
  },
  async setInitialState({ state, dispatch, rootGetters }, payload) {
    if (!payload?.useNotUserInfo) {
      const userInfo = await rootGetters['userInfo/uInfo'];
      if (userInfo && userInfo?.user?.packages?.length > 0) {
        userInfo?.user?.packages?.forEach((packageItem) => {
          packageItem.choosenPackage = false;
        });
      }
      dispatch('userInfo/loadInfo', userInfo, { root: true });
    }
    state.packagesAndOrderFormPopupStatus = false;
    state.reminderBannerNotInterestClicked = false;
    state.platformStepInfo = {
      currentStep: 1,
      totalStep: 4,
      backStepStatus: false,
      stepTitle: 'Upgrade your plan',
      stepDescription:
        'Level up your experience, enjoy enhanced features with our upgraded plan options',
      isSeoCheckboxSelected: false,
    };
    state.fullServiceStepInfo = {
      currentStep: 1,
      totalStep: 2,
      backStepStatus: false,
      stepTitle: 'Upgrade your plan',
      stepDescription:
        'Level up your experience, enjoy enhanced features with our upgraded plan options',
    };
    state.agencyStepInfo = {
      currentStep: 1,
      totalStep: 2,
      backStepStatus: false,
      stepTitle: 'Choose plan',
      stepDescription:
        'Level up your experience, enjoy enhanced features with our upgraded plan options',
    };
    state.currentStep = 1;
    state.totalStep = 4;
    state.stepType = 'Platform'; //Platform, FullService, Agency
    state.backStepStatus = false;
    state.selectableServices = [
      {
        id: 1,
        name: 'Platform',
        isActive: true,
      },
      {
        id: 2,
        name: 'Full Service',
        icon: 'star-rate',
        isActive: false,
      },
    ];
    state.storeList = [];
    state.selectedStoreList = [];
    state.kickoffDate = null;
    state.orderForm = {
      name: '',
      surname: '',
      email: '',
      callingCode: '',
      countryCode: 'US',
      phoneNumber: '',
      companyName: '',
      billingAddress: '',
      isCheckedTermsAndConditions: false,
    };
    state.communicationForm = {
      name: '',
      surname: '',
      email: '',
      callingCode: '',
      countryCode: 'US',
      phoneNumber: '',
      companyName: '',
      explanation: '',
    };
    state.stripePayment = {
      isSuccessPayment: false,
      paymentAmount: null,
      payLoadingView: false,
      payLoading: false,
    };
    state.calculatedData = {
      isLoading: false,
      calculatedPackageAmount: null,
      proratedAmount: null,
      processingFee: null,
      feeRatio: null,
      cancelPaymentAmount: null,
      cancelProccesingFee: null,
    };
    state.packageContent = '';
    state.pricingContent = '';
    state.acceptOfferRadio = true;
    state.reminderBannerStatus = false;
    state.reminderBannerTitle = '';
    state.reminderBannerRadioTitle = '';
    state.reminderBannerRadioText = '';
  },
  setReminderBannerNotInterestClicked({ commit }, payload) {
    commit('SET_REMINDER_BANNER_NOT_INTEREST_CLICKED', payload);
  },
  setStoreList({ commit }, payload) {
    commit('SET_STORE_LIST', payload);
  },
  setCalculatedData({ commit }, payload) {
    commit('SET_CALCULATED_DATA', payload);
  },
  setStripePayment({ commit }, payload) {
    commit('SET_STRIPE_PAYMENT', payload);
  },
  setInitialStripePayment({ commit }) {
    commit('SET_INITIAL_STRIPE_PAYMENT');
  },
  setBannerTextList({ state }, bannerType) {
    if (bannerType === 'reimbursement') {
      state.reminderBannerTitle = reminderBanner.reimbursement.title;
      state.reminderBannerRadioTitle = reminderBanner.reimbursement.radioTitle;
      state.reminderBannerRadioText = reminderBanner.reimbursement.radioText;
    } else {
      state.reminderBannerTitle = reminderBanner.platform.title;
      state.reminderBannerRadioTitle = reminderBanner.platform.radioTitle;
      state.reminderBannerRadioText = reminderBanner.platform.radioText;
    }
  },
  chooseNewPackage(
    { rootState, getters, state, dispatch },
    { isFirstStep, packageId }
  ) {
    const activeUser = rootState.AppActiveUser.user;

    activeUser.packages.forEach((packageItem) => {
      if (packageItem.packageType === packageId) {
        // Eğer seçilen paket 'isAdditionalPackage' özelliğine sahipse, sadece bu paketin 'choosenPackage' değerini değiştir
        if (packageItem.isAdditionalPackage) {
          packageItem.choosenPackage = !packageItem.choosenPackage;
        } else {
          // Eğer seçilen paket 'isAdditionalPackage' özelliğine sahip değilse ve zaten seçiliyse, seçimini kaldır
          if (packageItem.choosenPackage) {
            packageItem.choosenPackage = false;
          } else {
            // Eğer seçilen paket seçili değilse, diğer tüm bağımsız paketlerin 'choosenPackage' değerlerini false yap ve bu paketi true yap
            activeUser.packages.forEach((item) => {
              if (!item.isAdditionalPackage) {
                item.choosenPackage = false;
              }
            });
            packageItem.choosenPackage = true;
          }
        }
      }
    });

    const hasReimbursementSelected =
      getters.getChoosenAdditionalPackages.length > 0;
    const hasPlatformPackageSelected = !!getters.getChoosenPackage;

    if (!hasReimbursementSelected && !hasPlatformPackageSelected) {
      state.reminderBannerStatus = false;
      return;
    }
    if (isFirstStep && !getters.getPackagesConditionForUpgrade) {
      if (!hasReimbursementSelected) {
        const reimbursementPackage = activeUser.packages.find(
          (item) => item.isAdditionalPackage
        );
        reimbursementPackage.choosenPackage = true;
        state.acceptOfferRadio = true;
        state.reminderBannerStatus = true;
        state.activeBanner = 'platform';
        state.reminderBannerTitle = reminderBanner.platform.title;
        state.reminderBannerRadioTitle = reminderBanner.platform.radioTitle;
        state.reminderBannerRadioText = reminderBanner.platform.radioText;
      } else {
        state.reminderBannerStatus = true;
        if (!hasPlatformPackageSelected) {
          state.activeBanner = 'reimbursement';
          state.acceptOfferRadio = false;
          dispatch('setBannerTextList', 'reimbursement');
        } else {
          const essentialPackage = activeUser.packages.find(
            (item) => item.packageLevel === 1
          );
          if (!essentialPackage.choosenPackage && hasPlatformPackageSelected) {
            state.acceptOfferRadio = true;
            state.activeBanner = 'platform';
            dispatch('setBannerTextList', 'platform');
          } else {
            state.acceptOfferRadio = true;
            state.activeBanner = 'reimbursement';
            dispatch('setBannerTextList', 'reimbursement');
          }
        }
      }
    }
  },
  chooseAdditionalPackage(
    { rootState, state, getters, dispatch },
    { isFirstStep, packageId }
  ) {
    const activeUser = rootState.AppActiveUser.user;
    activeUser.packages.forEach((packageItem) => {
      if (packageItem.isAdditionalPackage) {
        if (packageItem.packageType === packageId) {
          packageItem.choosenPackage = !packageItem.choosenPackage;
          state.acceptOfferRadio = packageItem.choosenPackage;
        }
      }
    });
    const hasReimbursementSelected =
      getters.getChoosenAdditionalPackages.length > 0;
    const hasPlatformPackageSelected = !!getters.getChoosenPackage;

    if (!hasReimbursementSelected && !hasPlatformPackageSelected) {
      state.reminderBannerStatus = false;
      return;
    }
    if (isFirstStep) {
      if (!hasPlatformPackageSelected) {
        const essentialPackage = activeUser.packages.find(
          (item) => item.packageLevel === 1
        );
        essentialPackage.choosenPackage = true;
        state.reminderBannerStatus = true;
        state.acceptOfferRadio = true;
        state.activeBanner = 'reimbursement';
        dispatch('setBannerTextList', 'reimbursement');
      } else {
        state.reminderBannerStatus = true;
        if (!hasReimbursementSelected) {
          state.acceptOfferRadio = false;
          state.activeBanner = 'platform';
          dispatch('setBannerTextList', 'platform');
        } else {
          state.acceptOfferRadio = true;
          state.activeBanner = 'platform';
          dispatch('setBannerTextList', 'platform');
        }
      }
    }
  },
  increaseCurrentStep({ commit }, payload) {
    commit('INCREASE_CURRENT_STEP', payload);
  },
  decreaseCurrentStep({ commit }, payload) {
    commit('DECREASE_CURRENT_STEP', payload);
  },
  setBackStepStatus({ commit }, payload) {
    commit('SET_BACK_STEP_STATUS', payload);
  },
  setStepTitle({ commit }, payload) {
    commit('SET_STEP_TITLE', payload);
  },
  setStepDescription({ commit }, payload) {
    commit('SET_STEP_DESCRIPTION', payload);
  },
  setCurrentStepAndTotalStep({ commit }, payload) {
    commit('SET_CURRENT_STEP_AND_TOTAL_STEP', payload);
  },
  setSelectableServices({ commit }, payload) {
    commit('SET_SELECTABLE_SERVICES', payload);
  },
  setSelectedStores({ commit }, payload) {
    commit('SET_SELECTED_STORES', payload);
  },
  setSelectAllStores({ commit }, payload) {
    commit('SET_SELECT_ALL_STORES', payload);
  },
  setKickoffDate({ commit }, payload) {
    commit('SET_KICKOFF_DATE', payload);
  },
  setOrderFormInputs({ commit }, payload) {
    commit('SET_ORDER_FORM_INPUTS', payload);
  },
  setCommunicationFormInputs({ commit }, payload) {
    commit('SET_COMMUNICATION_FORM_INPUTS', payload);
  },
  setStepInfo({ commit }, payload) {
    commit('SET_STEP_INFO', payload);
  },
  setTotalStep({ commit }, payload) {
    commit('SET_TOTAL_STEP', payload);
  },
  setPackageContent({ commit }, payload) {
    commit('SET_PACKAGE_CONTENT', payload);
  },
  setPricingContent({ commit }, payload) {
    commit('SET_PRICING_CONTENT', payload);
  },
  setAcceptOfferRadio({ commit }, payload) {
    commit('SET_ACCEPT_OFFER_RADIO', payload);
  },
  setActiveBanner({ commit }, payload) {
    commit('SET_ACTIVE_BANNER', payload);
  },
  setOpenFromHeader({ commit }, payload) {
    commit('SET_OPEN_FROM_HEADER', payload);
  },
  openUpgradePopup({ getters, rootGetters, dispatch }) {
    const openFromHeader = getters.getOpenFromHeader;
    const accountType = rootGetters['userInfo/getAccountType'];
    const selectedService = getters.getSelectedService;
    const serviceId = selectedService?.id;
    let stepType;

    //0: seller | 1: agency
    if (accountType === 1) {
      stepType = 'agency';
    } else {
      stepType = serviceId === 1 ? 'platform' : 'fullService';
    }

    dispatch('chooseNewPackage', {
      isFirstStep: true,
      packageId: 11,
    });
    dispatch('setCurrentStepAndTotalStep', {
      stepType: stepType,
      currentStep:
        (getters.getHasReimbursementOnly && openFromHeader) ||
        stepType === 'agency'
          ? 1
          : 2,
      totalStep: 4,
    });
    dispatch('chooseAdditionalPackage', {
      isFirstStep: false,
      packageId: getters['getReimbursementPackage']?.packageType,
    });
    dispatch('setStepTitle', {
      stepType: stepType,
      title: 'What you’ll have',
    });
    dispatch('setStepDescription', {
      stepType: stepType,
      description:
        'Level up your experience, enjoy enhanced features with our upgraded plan options',
    });
    dispatch('setBackStepStatus', {
      stepType: stepType,
      status: false,
    });
    dispatch('setPackagesAndOrderFormPopupStatus', true);
  },

  // Cancellation
  setCancellationPopupStatus({ commit }, payload) {
    commit('SET_CANCELLATION_POPUP_STATUS', payload);
  },
  setCancellationStep({ commit }, payload) {
    commit('SET_CANCELLATION_STEP', payload);
  },
  setCancellationBackStepStatus({ commit }, payload) {
    commit('SET_CANCELLATION_BACK_STEP_STATUS', payload);
  },
  setCancellationReasonType({ commit }, payload) {
    commit('SET_CANCELLATION_REASON_TYPE', payload);
  },
  setCancellationExplanation({ commit }, payload) {
    commit('SET_CANCELLATION_EXPLANATION', payload);
  },
  setEmailToSendStatus({ commit }, payload) {
    commit('SET_EMAIL_TO_SEND_STATUS', payload);
  },
  async setInitialCancellationState({ state, rootGetters, dispatch }, payload) {
    state.cancellationStep = 1;
    state.cancellationBackStepStatus = false;
    state.cancellationReasonType = null;
    state.cancellationExplanation = '';
    state.iAggreeCheckbox = false;
    state.emailToSendStatus = false;

    state.offerHeader = '';
    state.offerFirstRadioText = '';
    state.offerFirstRadioDescription = '';
    state.offerFirstCheckboxText = '';

    state.offerSecondRadioText = '';
    state.offerSecondCheckboxText = '';

    state.isOfferRadioAccepted = true;
    state.isOfferCheckboxAccepted = true;

    state.lastSubEndDate = null;
    state.nextSubStartDate = null;
    state.cancelPaymentAmount = null;
    state.calculatedData = {
      isLoading: false,
      calculatedPackageAmount: null,
      proratedAmount: null,
      processingFee: null,
      feeRatio: null,
      cancelPaymentAmount: null,
      cancelProcessingFee: null,
    };

    if (!payload?.useNotUserInfo) {
      const userInfo = await rootGetters['userInfo/uInfo'];
      if (userInfo && userInfo?.user?.packages?.length > 0) {
        userInfo?.user?.packages?.forEach((packageItem) => {
          packageItem.choosenPackage = false;
        });
      }
      dispatch('userInfo/loadInfo', userInfo, { root: true });
    }
  },
  //Banner
  setInitialCancellationBanner({ state, getters, commit }) {
    const hasReimbursement = getters.getActiveReimbursementPackageType;
    const activeSubscriptionPackageObj =
      getters.getActiveSubscriptionPackageObj;
    const hasEssentialPackage = activeSubscriptionPackageObj
      ? getters.getActiveSubscriptionPackageObj?.packageLevel === 1
      : false;
    const hasAnyPlatformPackageOtherThanEssential = activeSubscriptionPackageObj
      ? getters.getActiveSubscriptionPackageObj?.packageLevel !== 1
      : false;

    if (
      hasReimbursement &&
      !hasEssentialPackage &&
      !hasAnyPlatformPackageOtherThanEssential
    ) {
      state.offerHeader = 'Exclusive Offer';
      state.offerFirstRadioText =
        'Consider Profit Analytics for $25/month (Essential Plan)';
      state.offerFirstRadioDescription =
        'Why? Because the FBA Reimbursement fee is 11% when bundled with other plans.';
      state.offerFirstCheckboxText =
        'Keep reimbursement with 11% commission rate';
      state.offerSecondRadioText = 'Not interested';
      state.offerSecondCheckboxText = '';

      commit('SET_IS_OFFER_RADIO_ACCEPTED', true);
      commit('SET_IS_OFFER_CHECKBOX_ACCEPTED', true);
      return;
    }

    if (
      hasReimbursement &&
      hasEssentialPackage &&
      !hasAnyPlatformPackageOtherThanEssential
    ) {
      state.offerHeader = 'Exclusive Offer';
      state.offerFirstRadioText = 'Keep FBA Reimbursement';
      state.offerFirstRadioDescription =
        'Your commission rate will be updated to 15%, to keep benefit from 11% commission rate, hold your platform plan bundled with FBA Reimbursement.';
      state.offerFirstCheckboxText = '';
      state.offerSecondRadioText = 'Not interested';
      state.offerSecondCheckboxText = '';

      commit('SET_IS_OFFER_RADIO_ACCEPTED', true);
      commit('SET_IS_OFFER_CHECKBOX_ACCEPTED', false);
      return;
    }

    if (
      hasReimbursement &&
      !hasEssentialPackage &&
      hasAnyPlatformPackageOtherThanEssential
    ) {
      state.offerHeader = 'Exclusive Offer';
      state.offerFirstRadioText =
        'Consider Profit Analytics for $25/month (Essential Plan)';
      state.offerFirstRadioDescription =
        'Experience the robust features of the Essential Plan prior to considering cancellation.';
      state.offerFirstCheckboxText =
        'Keep reimbursement with 11% commission rate';
      state.offerSecondRadioText = 'Not interested';
      state.offerSecondCheckboxText =
        'Keep reimbursement with 15% commission rate';

      commit('SET_IS_OFFER_RADIO_ACCEPTED', true);
      commit('SET_IS_OFFER_CHECKBOX_ACCEPTED', true);
      return;
    }

    if (
      !hasReimbursement &&
      hasEssentialPackage &&
      !hasAnyPlatformPackageOtherThanEssential
    ) {
      state.offerHeader = 'Exclusive Offer';
      state.offerFirstRadioText = 'Only reimbursement with 15% commission rate';
      state.offerFirstRadioDescription =
        'Your commission rate will be updated to 15%, to keep benefit from 11% commission rate, hold your platform plan bundled with FBA Reimbursement.';
      state.offerFirstCheckboxText = '';
      state.offerSecondRadioText = 'Not interested';
      state.offerSecondCheckboxText = '';

      commit('SET_IS_OFFER_RADIO_ACCEPTED', true);
      commit('SET_IS_OFFER_CHECKBOX_ACCEPTED', false);
      return;
    }

    if (
      !hasReimbursement &&
      !hasEssentialPackage &&
      hasAnyPlatformPackageOtherThanEssential
    ) {
      state.offerHeader = 'Exclusive Offer';
      state.offerFirstRadioText =
        'Consider Profit Analytics for $25/month (Essential Plan)';
      state.offerFirstRadioDescription =
        'Experience the robust features of the Essential Plan prior to considering cancellation.';
      state.offerFirstCheckboxText = 'Reimbursement with 11% commission rate';
      state.offerSecondRadioText = 'Not interested';
      state.offerSecondCheckboxText =
        'Only reimbursement with 15% commission rate';

      commit('SET_IS_OFFER_RADIO_ACCEPTED', true);
      commit('SET_IS_OFFER_CHECKBOX_ACCEPTED', true);
    }
  },
  setIsOfferRadioAccepted({ commit }, payload) {
    commit('SET_IS_OFFER_RADIO_ACCEPTED', payload);
  },
  setIsOfferCheckboxAccepted({ commit }, payload) {
    commit('SET_IS_OFFER_CHECKBOX_ACCEPTED', payload);
  },
  setIAggreeCheckbox({ commit }, payload) {
    commit('SET_I_AGGREE_CHECKBOX', payload);
  },
  setLastSubEndDate({ commit }, payload) {
    commit('SET_LAST_SUB_END_DATE', payload);
  },
  setNextSubStartDate({ commit }, payload) {
    commit('SET_NEXT_SUB_START_DATE', payload);
  },
  setCancelPaymentAmount({ commit }, payload) {
    commit('SET_CANCEL_PAYMENT_AMOUNT', payload);
  },
};
