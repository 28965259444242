import { validateText, validateEmail } from '@/helper/index.js';

export default {
  getStepInfo: (state) => {
    return (stepType) => {
      return state[`${stepType}StepInfo`];
    };
  },
  getActiveSubscriptionPackageType: (_, __, rootState) => {
    const selectedStore = rootState.selectedStore;
    return selectedStore?.subscriptionPackageType || null;
  },
  getActiveReimbursementPackageType: (_, __, rootState) => {
    const selectedStore = rootState.selectedStore;
    return selectedStore?.hasReimbursementService || false;
  },
  getCalculatedData: (state) => state.calculatedData,
  getStripePayment: (state) => state.stripePayment,
  getCurrentStepAndTotalStep: (state, getters) => {
    let hasActivePackage;
    if (getters.isReimbursementLegacy) {
      hasActivePackage = true;
    } else {
      hasActivePackage = getters.getPackages.some(
        (item) => item.packageType === getters.getActiveSubscriptionPackageType
      );
    }
    const hasAdvertisingStep =
      getters?.getChoosenPackage?.hasAdvertisingStep || false;
    return (stepType, currentStepNo) => {
      switch (stepType) {
        case 'platform':
          switch (currentStepNo) {
            case 1:
              if (!hasActivePackage) {
                if (hasAdvertisingStep) {
                  return {
                    nextStepNo: 3,
                    prevStepNo: 1,
                    totalStep: 4,
                  };
                } else {
                  return {
                    nextStepNo: 4,
                    prevStepNo: 1,
                    totalStep: 3,
                  };
                }
              } else {
                return {
                  nextStepNo: 2,
                  prevStepNo: 1,
                  totalStep: 3,
                };
              }
            case 2:
              if (hasAdvertisingStep) {
                return {
                  nextStepNo: 3,
                  prevStepNo: 1,
                  totalStep: 4,
                };
              } else {
                return {
                  nextStepNo: 4,
                  prevStepNo: 1,
                  totalStep: 4,
                };
              }
            case 3:
              if (!hasActivePackage) {
                return {
                  nextStepNo: 4,
                  prevStepNo: 1,
                  totalStep: 4,
                };
              } else {
                return {
                  nextStepNo: 4,
                  prevStepNo: 2,
                  totalStep: 4,
                };
              }
            case 4:
              if (!hasActivePackage) {
                if (hasAdvertisingStep) {
                  return {
                    nextStepNo: 5,
                    prevStepNo: 3,
                    totalStep: 4,
                  };
                } else {
                  return {
                    nextStepNo: 5,
                    prevStepNo: 1,
                    totalStep: 4,
                  };
                }
              } else {
                if (hasAdvertisingStep) {
                  return {
                    nextStepNo: 5,
                    prevStepNo: 3,
                    totalStep: 3,
                  };
                } else {
                  return {
                    nextStepNo: 5,
                    prevStepNo: 2,
                    totalStep: 2,
                  };
                }
              }
            case 5:
              return {
                nextStepNo: 5,
                prevStepNo: 4,
                totalStep: 5,
              };
            default:
              return {
                nextStepNo: 2,
                prevStepNo: 1,
                totalStep: 4,
              };
          }
        case 'fullService':
          return {
            nextStepNo: currentStepNo + 1,
            prevStepNo: currentStepNo - 1,
            totalStep: 2,
          };
        case 'agency':
          return {
            nextStepNo: currentStepNo + 1,
            prevStepNo: currentStepNo - 1,
            totalStep: 3,
          };
      }
    };
  },

  getStepTitleAndDescription: (_, getters) => {
    const hasActivePackage = getters.getPackages.some(
      (item) => item.packageType === getters.getActiveSubscriptionPackageType
    );
    const getWillContact = getters.getWillContact;
    return (stepType, currentStepNo) => {
      switch (stepType) {
        case 'platform':
          switch (currentStepNo) {
            case 1:
              if (!hasActivePackage) {
                return {
                  stepTitle: 'Start Your Monthly Plan',
                  stepDescription:
                    'Level up your experience, enjoy enhanced features with our upgraded plan options',
                };
              } else {
                return {
                  stepTitle: 'Upgrade Your Plan',
                  stepDescription:
                    'Level up your experience, enjoy enhanced features with our upgraded plan options',
                };
              }
            case 2:
              return {
                stepTitle: 'What you will have',
                stepDescription:
                  'Level up your experience, enjoy enhanced features with our upgraded plan options',
              };
            case 3:
              if (!hasActivePackage) {
                return {
                  stepTitle: 'Choose your stores for Advertising',
                  stepDescription:
                    'Level up your experience, enjoy enhanced features with our plan options',
                };
              } else {
                return {
                  stepTitle: 'Choose Your Stores for Advertising',
                  stepDescription:
                    'Level up your experience, enjoy enhanced features with our upgraded plan options',
                };
              }
            case 4:
              if (getWillContact) {
                return {
                  stepTitle: '',
                  stepDescription: '',
                };
              }
              return {
                stepTitle: 'Order form',
                stepDescription:
                  'Provide details and consent for the Eva SAAS Order Form to proceed',
              };
            default:
              return {
                stepTitle: '',
                stepDescription: '',
              };
          }
        case 'fullService':
          switch (currentStepNo) {
            case 1:
              if (!hasActivePackage) {
                return {
                  stepTitle: 'Get Your Plan',
                  stepDescription:
                    'Level up your experience, enjoy enhanced features with our plan options',
                };
              } else {
                return {
                  stepTitle: 'Upgrade your plan',
                  stepDescription:
                    'Level up your experience, enjoy enhanced features with our upgraded plan options',
                };
              }
            case 2:
              return {
                stepTitle: 'Full Service Request',
                stepDescription:
                  'Please include any details you would like to add and submit the form; We will contact you.',
              };
            default:
              return {
                stepTitle: '',
                stepDescription: '',
              };
          }
        case 'agency':
          switch (currentStepNo) {
            case 1:
              if (!hasActivePackage) {
                return {
                  stepTitle: 'Get Your Plan',
                  stepDescription:
                    'Level up your experience, enjoy enhanced features with our plan options',
                };
              } else {
                return {
                  stepTitle: 'Upgrade your plan',
                  stepDescription:
                    'Level up your experience, enjoy enhanced features with our upgraded plan options',
                };
              }
            case 2:
              return {
                stepTitle: 'Order form',
                stepDescription:
                  'Provide details and consent for the Eva SAAS Order Form to proceed',
              };
            default:
              return {
                stepTitle: '',
                stepDescription: '',
              };
          }
        default:
          return {
            stepTitle: '',
            stepDescription: '',
          };
      }
    };
  },
  getPackages: (_, __, rootState) => {
    return rootState?.AppActiveUser?.user?.packages || [];
  },
  getPlatformPackages: (_, __, rootState) => {
    return (
      rootState?.AppActiveUser?.user?.packages?.filter(
        (packageItem) => packageItem.subscriptionType === 1
      ) || []
    );
  },
  getFullServicePackages: (_, __, rootState) => {
    if (!rootState.AppActiveUser.user.packages) return [];
    return rootState.AppActiveUser.user.packages.filter(
      (packageItem) => packageItem.subscriptionType === 2
    );
  },
  getUpgradePackages: (_, getters) => {
    if (getters.getPackagesConditionForUpgrade) {
      return [
        getters.getReimbursementPackage,
        getters.getGrowthPackage,
        getters.getEssentialPackage,
      ];
    }
  },
  getStoreList: (state) => {
    return state.storeList;
  },
  getSelectedStoreList: (state) => state.selectedStoreList,
  getPopupIsActive: (state) => state.packagesAndOrderFormPopupStatus,
  getCurrentStep: (state) => state.currentStep,
  getTotalStep: (state) => state.totalStep,
  getBackStepStatus: (state) => state.backStepStatus,
  getServices: (state) => state.selectableServices,
  getReimbursementPackage: (_, __, rootState) => {
    return (
      rootState?.AppActiveUser?.user?.packages?.find(
        (packageItem) => packageItem.isAdditionalPackage
      ) || []
    );
  },
  getEssentialPackage: (_, __, rootState) => {
    return (
      rootState?.AppActiveUser?.user?.packages?.find(
        (packageItem) => packageItem.packageType === 11
      ) || []
    );
  },
  getGrowthPackage: (_, __, rootState) => {
    return (
      rootState?.AppActiveUser?.user?.packages?.find(
        (packageItem) => packageItem.packageType === 10
      ) || []
    );
  },
  isReimbursementLegacy: (_, getters) => {
    return (
      getters.getActiveSubscriptionPackageType &&
      getters.getActiveSubscriptionPackageType === 7
    );
  },
  isReimbursement: (_, getters) => {
    return (
      getters.getActiveSubscriptionPackageType &&
      getters.getActiveSubscriptionPackageType === 14
    );
  },
  isGrowth: (_, getters) => {
    return (
      getters.getActiveSubscriptionPackageType &&
      getters.getActiveSubscriptionPackageType === 10
    );
  },
  getCurrentPackage: (_, getters, rootState) => {
    if (getters.getActiveSubscriptionPackageType === 7) {
      return getters.getReimbursementPackage;
    }

    return (
      rootState?.AppActiveUser?.user?.packages?.find(
        (packageItem) =>
          packageItem.packageType === getters.getActiveSubscriptionPackageType
      ) || null
    );
  },
  getChoosenPackage: (_, __, rootState) => {
    const platformPackages = rootState?.AppActiveUser?.user?.packages?.filter(
      (packageItem) =>
        packageItem.subscriptionType === 1 && !packageItem.isAdditionalPackage
    );
    return platformPackages && platformPackages.length > 0
      ? platformPackages.find((packageItem) => packageItem.choosenPackage)
      : null;
  },
  getFullServiceChoosenPackage: (_, __, rootState) => {
    const fullServicePackages = rootState.AppActiveUser.user.packages.filter(
      (packageItem) => packageItem.subscriptionType === 2
    );
    return fullServicePackages.find(
      (packageItem) => packageItem.choosenPackage
    );
  },
  getChoosenAdditionalPackages: (_, __, rootState) => {
    const additionalPackages = rootState?.AppActiveUser?.user?.packages?.filter(
      (packageItem) => packageItem.isAdditionalPackage
    );
    return additionalPackages && additionalPackages.length > 0
      ? additionalPackages.filter((packageItem) => packageItem.choosenPackage)
      : [];
  },
  getSelectedService: (state) => {
    return state.selectableServices.find((service) => service.isActive);
  },
  getKickoffDate: (state) => state.kickoffDate,
  getOrderFormInput(state) {
    return (val) => {
      return state.orderForm[val];
    };
  },
  getHasReimbursementOnly: (_, getters) => {
    return getters.isReimbursementLegacy || getters.isReimbursement;
  },
  getPackagesConditionForUpgrade(_, getters, rootGetters) {
    const currentPackageType = getters.getCurrentPackage?.packageType;
    const hasReimbursement = currentPackageType === 14;
    const isAdditionalReimbursement = getters.getActiveReimbursementPackageType;

    return (
      getters.getHasEssentialPackage ||
      getters.isReimbursementLegacy ||
      (isAdditionalReimbursement && hasReimbursement)
    );
  },
  getOptionalFbaReimbursement(_, getters) {
    const isReimbursementLegacy =
      getters.getActiveSubscriptionPackageType &&
      getters.getActiveSubscriptionPackageType === 7;
    const hasReimbursement = getters.getActiveReimbursementPackageType;
    const choosenPackage = getters.getChoosenPackage;

    return (
      (hasReimbursement || isReimbursementLegacy) &&
      choosenPackage.value?.packageType === 11
    );
  },
  getDisableOptionalFbaReimbursementButton(_, getters) {
    return getters.getActiveReimbursementPackageType;
  },
  getCommunicationFormInput(state) {
    return (val) => {
      return state.communicationForm[val];
    };
  },

  getPackageButtonText: (_, getters) => {
    const choosenAdditionalPackages =
      getters.getChoosenAdditionalPackages.length > 0;
    const isAdditionalReimbursement = getters.getActiveReimbursementPackageType;

    return (packageItem, isSmallCard = false) => {
      const packageInformationList = getters.getPlatformPackages;
      const hasActivePackage = packageInformationList.some(
        (packageItem) =>
          packageItem.packageType === getters.getActiveSubscriptionPackageType
      );

      if (isSmallCard) {
        if (getters.getPackagesConditionForUpgrade) {
          if (choosenAdditionalPackages) {
            return isAdditionalReimbursement
              ? 'Already Added'
              : 'Chosen to add';
          } else {
            return 'Add to my plan';
          }
        }

        if (getters.getAcceptOfferRadio) {
          return 'Already Added';
        } else {
          return 'Choose Plan';
        }
      }

      if (packageItem.choosenPackage && !packageItem.isAdditionalPackage) {
        return 'Chosen plan';
      } else if (
        packageItem.choosenPackage &&
        packageItem.isAdditionalPackage &&
        hasActivePackage
      ) {
        if (
          getters.getPackagesConditionForUpgrade &&
          getters.getHasReimbursementOnly
        ) {
          return 'Your Plan';
        } else if (getters.getPackagesConditionForUpgrade) {
          return 'Already Added';
        } else {
          return 'Added';
        }
      } else if (
        packageItem.choosenPackage &&
        packageItem.isAdditionalPackage &&
        !hasActivePackage
      ) {
        if (
          getters.getPackagesConditionForUpgrade &&
          getters.getHasReimbursementOnly
        ) {
          return 'Your Plan';
        } else if (getters.getPackagesConditionForUpgrade) {
          return 'Already Added';
        } else {
          return 'Chosen plan';
        }
      } else if (
        packageItem.packageType === getters.getActiveSubscriptionPackageType
      ) {
        return 'Your plan';
      } else if (packageItem.isAdditionalPackage && hasActivePackage) {
        return 'Add to my plan';
      } else if (packageItem.isAdditionalPackage && !hasActivePackage) {
        return 'Choose plan';
      } else {
        return 'Choose plan';
      }
    };
  },
  getFullServicePackageButtonText: (_, getters) => {
    return (packageItem) => {
      const packageInformationList = getters.getFullServicePackages;
      const hasActivePackage = packageInformationList.some(
        (packageItem) =>
          packageItem.packageType === getters.getActiveSubscriptionPackageType
      );
      if (packageItem.choosenPackage && !packageItem.isAdditionalPackage) {
        return 'Chosen plan';
      } else if (
        packageItem.choosenPackage &&
        packageItem.isAdditionalPackage &&
        hasActivePackage
      ) {
        return 'Added';
      } else if (
        packageItem.choosenPackage &&
        packageItem.isAdditionalPackage &&
        !hasActivePackage
      ) {
        return 'Chosen plan';
      } else if (
        packageItem.packageType === getters.getActiveSubscriptionPackageType
      ) {
        return 'Your plan';
      } else if (packageItem.isAdditionalPackage && hasActivePackage) {
        return 'Add to my plan';
      } else if (packageItem.isAdditionalPackage && !hasActivePackage) {
        return 'Choose plan';
      } else {
        return 'Choose plan';
      }
    };
  },

  getStepType(state) {
    return state.stepType;
  },

  getStepCompleted(_, getters) {
    //stepType: Platform, FullService, Agency
    return (stepNo, stepType) => {
      return getters[`${stepType}Step${stepNo}Completed`];
    };
  },
  platformStep1Completed(_, getters) {
    return getters.getPackagesConditionForUpgrade
      ? !!getters.getChoosenPackage
      : !!getters.getChoosenPackage ||
          getters.getChoosenAdditionalPackages.length > 0;
  },
  platformStep2Completed() {
    return true;
  },
  platformStep3Completed(state) {
    return state.selectedStoreList.length > 0 && state.kickoffDate;
  },
  platformStep3ValidationMessage(state) {
    return {
      marketplaceCard:
        state.selectedStoreList.length > 0
          ? ''
          : 'At least one marketplace must be marked.',
      kickoffDate: state.kickoffDate ? '' : 'Selecting a date is mandatory.',
    };
  },

  fullServiceStep1Completed(_, getters) {
    return !!getters.getFullServiceChoosenPackage;
  },
  fullServiceStep2Completed(state) {
    const nameIsValid = validateText(state.communicationForm.name, 3, 'Name');
    const surnameIsValid = validateText(
      state.communicationForm.surname,
      3,
      'Surname'
    );
    const emailIsValid = validateEmail(state.communicationForm.email);
    const phoneNumber = state.communicationForm.phoneNumber
      ? { error: false, message: '' }
      : { error: true, message: 'Phone number must be valid' };
    const companyNameIsValid = validateText(
      state.communicationForm.companyName,
      3,
      'Company name'
    );
    const explanationIsValid = validateText(
      state.communicationForm.explanation,
      3,
      'Explanation'
    );

    return (
      !nameIsValid.error &&
      !surnameIsValid.error &&
      !emailIsValid.error &&
      !phoneNumber.error &&
      !companyNameIsValid.error &&
      !explanationIsValid.error
    );
  },

  agencyStep1Completed(_, getters) {
    return getters.getPackagesConditionForUpgrade
      ? !!getters.getChoosenPackage
      : !!getters.getChoosenPackage ||
          getters.getChoosenAdditionalPackages.length > 0;
  },

  orderFormIsCompleted(state) {
    const nameIsValid = validateText(state.orderForm.name, 3, 'Name');
    const surnameIsValid = validateText(state.orderForm.surname, 3, 'Surname');
    const emailIsValid = validateEmail(state.orderForm.email);
    const phoneNumber = state.orderForm.phoneNumber
      ? { error: false, message: '' }
      : { error: true, message: 'Phone number must be valid' };
    const companyNameIsValid = validateText(
      state.orderForm.companyName,
      3,
      'Company name'
    );
    const billingAddressIsValid = validateText(
      state.orderForm.billingAddress,
      3,
      'Billing address'
    );
    const termsAndConditionIsValid =
      !state.orderForm.isCheckedTermsAndConditions;

    return (
      !nameIsValid.error &&
      !surnameIsValid.error &&
      !emailIsValid.error &&
      !phoneNumber.error &&
      !companyNameIsValid.error &&
      !billingAddressIsValid.error &&
      !termsAndConditionIsValid
    );
  },
  getOrderFormValidationMessage(state) {
    const nameIsValid = validateText(state.orderForm.name, 3, 'Name');
    const surnameIsValid = validateText(state.orderForm.surname, 3, 'Surname');
    const emailIsValid = validateEmail(state.orderForm.email);
    const phoneNumber = state.orderForm.phoneNumber
      ? { error: false, message: '' }
      : { error: true, message: 'Phone number must be valid' };
    const companyNameIsValid = validateText(
      state.orderForm.companyName,
      3,
      'Company name'
    );
    const billingAddressIsValid = validateText(
      state.orderForm.billingAddress,
      3,
      'Billing address'
    );
    const termsAndConditionIsValid =
      !state.orderForm.isCheckedTermsAndConditions;

    return (value) => {
      switch (value) {
        case 'name':
          return nameIsValid.message;
        case 'surname':
          return surnameIsValid.message;
        case 'email':
          return emailIsValid.message;
        case 'phoneNumber':
          return phoneNumber.message;
        case 'companyName':
          return companyNameIsValid.message;
        case 'billingAddress':
          return billingAddressIsValid.message;
        case 'termsAndCondition':
          return termsAndConditionIsValid ? '*Check is required' : '';
        default:
          return '';
      }
    };
  },

  communicationFormIsCompleted(state) {
    const nameIsValid = validateText(state.communicationForm.name, 3, 'Name');
    const surnameIsValid = validateText(
      state.communicationForm.surname,
      3,
      'Surname'
    );
    const emailIsValid = validateEmail(state.communicationForm.email);
    const phoneNumber = state.communicationForm.phoneNumber
      ? { error: false, message: '' }
      : { error: true, message: 'Phone number must be valid' };
    const companyNameIsValid = validateText(
      state.communicationForm.companyName,
      3,
      'Company name'
    );
    const explanationIsValid = validateText(
      state.communicationForm.explanation,
      3,
      'Explanation'
    );

    return (
      !nameIsValid.error &&
      !surnameIsValid.error &&
      !emailIsValid.error &&
      !phoneNumber.error &&
      !companyNameIsValid.error &&
      !explanationIsValid.error
    );
  },
  getCommunicationFormValidationMessage(state) {
    const nameIsValid = validateText(state.communicationForm.name, 3, 'Name');
    const surnameIsValid = validateText(
      state.communicationForm.surname,
      3,
      'Surname'
    );
    const emailIsValid = validateEmail(state.communicationForm.email);
    const phoneNumber = state.communicationForm.phoneNumber
      ? { error: false, message: '' }
      : { error: true, message: 'Phone number must be valid' };
    const companyNameIsValid = validateText(
      state.communicationForm.companyName,
      3,
      'Company name'
    );
    const explanationIsValid = validateText(
      state.communicationForm.explanation,
      3,
      'Explanation'
    );

    return (value) => {
      switch (value) {
        case 'name':
          return nameIsValid.message;
        case 'surname':
          return surnameIsValid.message;
        case 'email':
          return emailIsValid.message;
        case 'phoneNumber':
          return phoneNumber.message;
        case 'companyName':
          return companyNameIsValid.message;
        case 'explanation':
          return explanationIsValid.message;
        default:
          return '';
      }
    };
  },
  getPackageContent: (state) => state.packageContent.replace(/\n/g, '<br>'),
  getPricingContent: (state) => state.pricingContent.replace(/\n/g, '<br>'),

  //subscription authorization getters
  getCurrentPackageInvisibleRoutes: (_, getters) => {
    const currentPackage = getters.getCurrentPackage;
    return currentPackage?.authorization?.inaccessibleRoutes || [];
  },
  getCurrentPackageInvisibleReports: (_, getters) => {
    const currentPackage = getters.getCurrentPackage;
    return currentPackage?.authorization?.invisibleReports || [];
  },
  getCurrentPackageInvisibleFeatures: (_, getters) => {
    const currentPackage = getters.getCurrentPackage;
    return currentPackage?.authorization?.invisibleFeatures || [];
  },
  //-----------------------------------
  getAcceptOfferRadio: (state) => state.acceptOfferRadio,
  getReminderBannerStatus: (state) => state.reminderBannerStatus,
  getReminderBannerTitle: (state) => state.reminderBannerTitle,
  getReminderBannerRadioTitle: (state) => state.reminderBannerRadioTitle,
  getReminderBannerRadioText: (state) => state.reminderBannerRadioText,
  getActiveBanner: (state) => state.activeBanner,
  getOpenFromHeader: (state) => state.openFromHeader,

  // Cancellation
  getHasEssentialPackage: (_, getters) => {
    const activeSubscriptionPackageObj =
      getters.getActiveSubscriptionPackageObj;
    return activeSubscriptionPackageObj
      ? getters.getActiveSubscriptionPackageObj?.packageLevel === 1
      : false;
  },
  getCancellationPopupStatus: (state) => state.cancellationPopupStatus,
  getCancellationStep: (state) => state.cancellationStep,
  getCancellationBackStepStatus: (state) => state.cancellationBackStepStatus,
  getCancellationReasonType: (state) => state.cancellationReasonType,
  getCancellationExplanation: (state) => state.cancellationExplanation,
  getEmailToSendStatus: (state) => state.emailToSendStatus,

  getAllPlatformPackageList: (_, __, rootState) => {
    return (
      rootState.AppActiveUser.user?.packageInformation?.packageInformation?.filter(
        (item) => item.subscriptionType === 1
      ) || []
    );
  },
  getActivePackageFromAllPlatformPackageList: (_, getters) => {
    const allPlatformPackageList = getters.getAllPlatformPackageList;
    const activeSubscriptionPackageType =
      getters.getActiveSubscriptionPackageType;
    //without reimbursement
    const reimbursementExcludedPlatformPackages = allPlatformPackageList.filter(
      (item) => !item.packageName.includes('Reimbursement')
    );
    const activePackageFromAllPlatformPackageList =
      reimbursementExcludedPlatformPackages.find(
        (item) => item.pricingStatus === activeSubscriptionPackageType
      );
    return activePackageFromAllPlatformPackageList;
  },
  getActiveSubscriptionPackageObj: (_, getters) => {
    let activeSubscriptionPackageObj = null;

    const packages =
      getters?.getPlatformPackages?.filter(
        (item) => !item.isAdditionalPackage
      ) || [];
    const activeSubscriptionPackageType =
      getters.getActiveSubscriptionPackageType;
    const activePackageFromPackages = packages.find(
      (item) => item.packageType === activeSubscriptionPackageType
    );
    const activePackageFromAllPlatformPackageList =
      getters.getActivePackageFromAllPlatformPackageList;
    if (activePackageFromPackages) {
      activeSubscriptionPackageObj = {
        ...activePackageFromPackages,
        info: 'platform',
      };
    } else if (activePackageFromAllPlatformPackageList) {
      activeSubscriptionPackageObj = {
        icon: 'product',
        iconSet: 'carbon',
        name: activePackageFromAllPlatformPackageList.packageName,
        packageType: activePackageFromAllPlatformPackageList.pricingStatus,
        info: 'platform',
      };
    }
    return activeSubscriptionPackageObj;
  },
  getCurrentPlanListForCancellation: (_, getters) => {
    const activePackageFromAllPlatformPackageList =
      getters.getActivePackageFromAllPlatformPackageList;

    let activeSubscriptionPackageObj = getters.getActiveSubscriptionPackageObj;

    const reimbursementObj = getters.getReimbursementPackage;

    const hasReimbursement = getters.getActiveReimbursementPackageType;

    if (hasReimbursement && activePackageFromAllPlatformPackageList) {
      return [activeSubscriptionPackageObj, reimbursementObj];
    }
    if (hasReimbursement && !activePackageFromAllPlatformPackageList) {
      return [reimbursementObj];
    }
    if (!hasReimbursement && activePackageFromAllPlatformPackageList) {
      return [activeSubscriptionPackageObj];
    }
    return null;
  },

  getCancelledList: (_, getters) => {
    const currentPlanListForCancellation =
      getters.getCurrentPlanListForCancellation;

    const notInterestedSelected = !getters.getIsOfferRadioAccepted;

    const isOfferCheckboxSelected = getters.getIsOfferCheckboxAccepted;

    const hasEssentialPackage = getters.getHasEssentialPackage;

    if (notInterestedSelected) {
      if (isOfferCheckboxSelected) {
        return currentPlanListForCancellation.filter(
          (item) => !item.isAdditionalPackage
        );
      } else {
        return currentPlanListForCancellation;
      }
    } else {
      if (hasEssentialPackage) {
        return currentPlanListForCancellation.filter(
          (item) => !item.isAdditionalPackage
        );
      } else {
        if (isOfferCheckboxSelected) {
          return currentPlanListForCancellation.filter(
            (item) => !item.isAdditionalPackage
          );
        } else {
          return currentPlanListForCancellation;
        }
      }
    }
  },

  getWhatPackagesWillYouHave: (_, getters, rootState) => {
    const notInterestedSelected = !getters.getIsOfferRadioAccepted;

    const isOfferCheckboxSelected = getters.getIsOfferCheckboxAccepted;

    const hasEssentialPackage = getters.getHasEssentialPackage;

    const essentialPackage = rootState?.AppActiveUser?.user?.packages?.find(
      (packageItem) => packageItem.packageLevel === 1
    );

    const reimbursementPackage = rootState?.AppActiveUser?.user?.packages?.find(
      (packageItem) => packageItem.isAdditionalPackage
    );

    if (notInterestedSelected) {
      if (isOfferCheckboxSelected) {
        return [reimbursementPackage];
      } else {
        return [];
      }
    } else {
      if (hasEssentialPackage) {
        return [reimbursementPackage];
      } else {
        if (isOfferCheckboxSelected) {
          return [essentialPackage, reimbursementPackage];
        } else {
          return [essentialPackage];
        }
      }
    }
  },

  //Banner
  getCancellationOfferBanner: (state) => {
    return {
      offerHeader: state.offerHeader,
      offerFirstRadioText: state.offerFirstRadioText,
      offerFirstRadioDescription: state.offerFirstRadioDescription,
      offerFirstCheckboxText: state.offerFirstCheckboxText,
      offerSecondRadioText: state.offerSecondRadioText,
      offerSecondCheckboxText: state.offerSecondCheckboxText,
    };
  },
  getIsOfferRadioAccepted: (state) => state.isOfferRadioAccepted,
  getIsOfferCheckboxAccepted: (state) => state.isOfferCheckboxAccepted,
  //-----------------

  getCancellationStepCompleted: (_, getters) => {
    return (stepNo) => {
      return getters[`cancellationStep${stepNo}Completed`];
    };
  },
  cancellationStep1Completed: (state) => {
    if (state.cancellationReasonType === 6) {
      return state.cancellationExplanation.trim().length > 0;
    }
    return !!state.cancellationReasonType;
  },

  getIAgreeCheckbox: (state) => state.iAggreeCheckbox,

  getLastSubEndDate: (state) => state.lastSubEndDate,
  getNextSubStartDate: (state) => state.nextSubStartDate,
  getCancelPaymentAmount: (state) => state.cancelPaymentAmount,
  getIsSeoCheckboxSelected: (state) => {
    return state.platformStepInfo.isSeoCheckboxSelected;
  },

  getWillContact: (_, getters, rootState) => {
    const storeList = getters.getStoreList;
    const selectedStores = getters.getSelectedStoreList;

    if (selectedStores.length === 0) {
      return false;
    }
    if (selectedStores.length === 1) {
      const selectedStore = selectedStores[0];
      const foundParentAsinCount = storeList.find(
        (e) => e.storeId === selectedStore.storeId
      );

      return foundParentAsinCount?.parentAsinCount >= 500;
    }

    return true;
  },

  getPackageVersionMap: (_, __, rootState) => {
    return rootState?.AppActiveUser?.user?.packageVersionMap || [];
  },
};
