export default {
  isRuleResultsFilterReady(state) {
    return state.isRuleResultsFilterReady;
  },

  ruleResultsTableData(state) {
    return state.ruleResultsTableData;
  },
  getActiveRuleTab(state) {
    return state.activeRuleTab;
  },

  ruleResultsTableConfig(state) {
    return state.ruleResultsTableConfig;
  },

  ruleResultsPageSize(state) {
    return state.ruleResultsPageSize;
  },

  ruleResultFilters(state) {
    return state.ruleResultFilters;
  },

  ruleResultDetail(state) {
    return state.ruleResultDetail;
  },

  tableData(state) {
    return state.tableData;
  },

  tableConfig(state) {
    return state.tableConfig;
  },

  historyTableConfig(state) {
    return state.historyTableConfig;
  },

  filter(state) {
    return state.filter;
  },

  filterPreSelect(state) {
    return state.filterPreSelect;
  },

  sort(state) {
    return state.sort;
  },

  dateRange(state) {
    return state.dateRange;
  },
  dashboardDateRange(state) {
    return state.dashboardDateRange;
  },

  dashboardDateRangeType(state) {
    return state.dashboardDateRangeType;
  },

  pageInfo(state) {
    return state.pageInfo;
  },

  historyPageInfo(state) {
    return state.historyPageInfo;
  },

  activeTab(state) {
    return state.activeTab;
  },

  serviceNotify(state) {
    return state.serviceNotify;
  },

  toolbarData(state) {
    return state.toolbarData;
  },

  toolbarOptions(state) {
    return state.toolbarOptions;
  },

  updateInformation(state) {
    return state.updateInformation;
  },

  pageOptions(state) {
    return state.pageOptions;
  },

  automation(state) {
    return state.automation;
  },

  additionalFeatures(state) {
    return state.additionalFeatures;
  },

  updateHistory(state) {
    return state.updateHistory;
  },

  getCurrency(state) {
    return state.dashboardData.currency;
  },

  getLastPeriodDifference(state) {
    return state.dashboardData.LastPeriodDifference;
  },

  getTableHeaders(state) {
    return state.dashboardData.TableHeaders;
  },

  getTablePeriods(state) {
    return state.dashboardData.TablePeriods;
  },

  getTableChart(state) {
    return state.dashboardData.TableChart;
  },

  getLastSyncTime(state) {
    return state.dashboardData.lastSyncTime;
  },

  getConversionRate(state) {
    return state.dashboardData.conversionRate;
  },

  getOrdersAndPerformance(state) {
    return state.dashboardData.ordersAndPerformance;
  },

  getClickSessionsAndCtr(state) {
    return state.dashboardData.clickSessionsAndCtr;
  },

  getAverageOrderValue(state) {
    return state.dashboardData.averageOrderValue;
  },

  getSalesSpendAndPerformance(state) {
    return state.dashboardData.salesSpendAndPerformance;
  },

  getPeriodDates(state) {
    return state.dashboardData.periodDates;
  },

  getProductLevelSelectedDetail(state) {
    return state.ProductLevelSettings.selectedDetail;
  },

  getProductLevelFilter(state) {
    return state.ProductLevelSettings.filter;
  },

  getProductLevelSearchText(state) {
    return state.ProductLevelSettings.searchText;
  },
  getAdvDataStatus(state) {
    return state.advDataStatus;
  },

  getForecastRequestStatus(state) {
    return state.forecastRequestStatus;
  },

  getForecastData(state) {
    return state.forecastData;
  },

  getAdvertisingAvailableDateRange(state) {
    return state.advertisingAvailableDateRange;
  },

  getSmartFilter(state) {
    return state.analyticsSmartFilters;
  },

  getSelectedAnalyticsSmartFilter(state) {
    return state.selectedAnalyticsSmartFilter;
  },

  getAnalyticsChartData(state) {
    return state.analyticsChartData;
  },

  getDashboardTrendReportTab(state) {
    return state.dashboardTrendReportTab;
  },

  getTrendReportDateRange(state) {
    return state.trendReportdateRange;
  },

  getTrendReportOptions(state) {
    return state.trendReportChartOptions;
  },

  getAdvertisingAnalyticsAvaliableDateRange(state) {
    return state.advertisingAnalyticsAvaliableDateRange;
  },

  getAdvAnalyticsDateRangeType(state) {
    return state.getAdvAnalyticsDateRangeType;
  },

  getRuleResultAvaliableDateRange(state) {
    return state.ruleResultAvaliableDateRange;
  },
};
